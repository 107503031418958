import { LangEnum, SrcPartnerEnum } from '@/app/utils/constants';

type CaliforniaPrivacyPolicy = string | undefined;

type PrivacyPolicy = {
  url: string;
  frUrl?: string;
};

type TermsAndConditions = {
  url: string;
  frUrl?: string;
};

type AccessibilityStatement = string | undefined;

export type FooterLinks = {
  privacyPolicy: PrivacyPolicy;
  caPrivacyPolicy?: CaliforniaPrivacyPolicy;
  termsAndConditions?: TermsAndConditions;
  accessibilityStatement?: AccessibilityStatement;
};

const footerLinks: {
  [key in SrcPartnerEnum]?: FooterLinks;
} = {
  [SrcPartnerEnum.Lowes]: {
    privacyPolicy: {
      url: 'https://lowes.myhomeprojectcenter.com/privacy-policy-licensing/',
    },
    caPrivacyPolicy: 'https://lowes.myhomeprojectcenter.com/privacy-policy-licensing/#CA',
  },
  [SrcPartnerEnum.AceHardware]: {
    privacyPolicy: {
      url: 'https://acehardware.myhomeprojectcenter.com/privacy-policy-licensing/',
    },
    caPrivacyPolicy: 'https://acehardware.myhomeprojectcenter.com/privacy-policy-licensing/#CA',
  },
  [SrcPartnerEnum.BJs]: {
    privacyPolicy: {
      url: 'https://bjs.myhomeprojectcenter.com/privacy-policy-licensing/',
    },
    caPrivacyPolicy: 'https://bjs.myhomeprojectcenter.com/california-privacy-policy/',
  },
  // [SrcPartnerEnum.Calibamboo]: {
  //     policy: {
  //         url: 'https://uatcalibamboo.myhomeprojectcenter.com/privacy-policy-licensing/',
  //         californiaUrl: 'https://uatcalibamboo.myhomeprojectcenter.com/privacy-policy-licensing/#CA',
  //     }
  //   },
  [SrcPartnerEnum.FND]: {
    privacyPolicy: {
      url: 'https://pros.myhomeprojectcenter.com/privacy-policy-licensing/',
    },
    caPrivacyPolicy: 'https://pros.myhomeprojectcenter.com/privacy-policy-licensing/#CA',
  },
  [SrcPartnerEnum.Sams]: {
    privacyPolicy: {
      url: 'https://samsclub-myprojects.confidentremodels.com/privacy-policy-licensing/',
    },
    caPrivacyPolicy:
      'https://samsclub-myprojects.confidentremodels.com/privacy-policy-licensing/#CA',
  },
  [SrcPartnerEnum.TrueValue]: {
    privacyPolicy: {
      url: 'https://truevalue.myhomeprojectcenter.com/privacy-policy/',
    },
    caPrivacyPolicy: 'https://truevalue.myhomeprojectcenter.com/privacy-policy/#CA',
  },
  //   [SrcPartnerEnum.wayfair]: {
  //     policy: {
  //         url: 'https://uatwayfair.myhomeprojectcenter.com/privacy-policy-licensing/',
  //         californiaUrl: 'https://uatwayfair.myhomeprojectcenter.com/privacy-policy-licensing/#CA',
  //       },
  //   },
  [SrcPartnerEnum.Rona]: {
    privacyPolicy: {
      url: 'https://www.rona.ca/en/privacy-policy',
      frUrl: 'https://www.rona.ca/fr/privacy-policy',
    },
    termsAndConditions: {
      url: 'https://www.rona.ca/en/terms-and-conditions',
      frUrl: 'https://www.rona.ca/fr/terms-and-conditions',
    },
  },
  [SrcPartnerEnum.ProSource]: {
    privacyPolicy: {
      url: 'https://prosource.confidentremodels.com/privacy-policy-licensing/',
    },
    caPrivacyPolicy: 'https://prosource.confidentremodels.com/privacy-policy-licensing/#CA',
  },
  [SrcPartnerEnum.FlooringAmericaInHome]: {
    privacyPolicy: {
      url: 'https://flooringamerica.confidentremodels.com/privacy-policy-licensing/',
    },
    caPrivacyPolicy: 'https://flooringamerica.confidentremodels.com/privacy-policy-licensing/#CA',
  },
  [SrcPartnerEnum.CarpetOne]: {
    privacyPolicy: {
      url: 'https://carpetone.confidentremodels.com/privacy-policy-licensing/',
    },
    caPrivacyPolicy: 'https://carpetone.confidentremodels.com/privacy-policy-licensing/#CA',
  },
  [SrcPartnerEnum.NorthernTool]: {
    accessibilityStatement: 'https://northerntoolinstallation.com/accessibility-statement/',
    privacyPolicy: {
      url: 'https://northerntoolinstallation.com/privacy-policy-licensing/',
    },
    caPrivacyPolicy: 'https://northerntoolinstallation.com/privacy-policy-licensing/#CA',
  },
};

export const getFooterLinksByRetailer = (retailer: SrcPartnerEnum) => footerLinks[retailer];

export const getFooterLink = (
  link: 'terms' | 'caPolicy' | 'policy' | 'accessibilityStatement',
  lang: string,
  footerLinks?: FooterLinks,
): string => {
  let url: string | undefined;

  switch (link) {
    case 'terms':
      if (lang === LangEnum.FR_CA && footerLinks?.termsAndConditions?.frUrl) {
        url = footerLinks?.termsAndConditions?.frUrl;
      } else {
        url = footerLinks?.termsAndConditions?.url;
      }
      break;
    case 'caPolicy':
      url = footerLinks?.caPrivacyPolicy;
      break;
    case 'accessibilityStatement':
      url = footerLinks?.accessibilityStatement;
      break;
    case 'policy':
      if (lang === LangEnum.FR_CA && footerLinks?.privacyPolicy?.frUrl) {
        url = footerLinks?.privacyPolicy?.frUrl;
      } else {
        url = footerLinks?.privacyPolicy.url;
      }
      break;
    default:
      console.warn('Footer link not supported:', link);
  }

  return url ?? '';
};
