import { createContext, ReactNode, useEffect, useState } from 'react';
import { Category, FeatureFlags } from '@/app/types/models';
import { getRetailerMappingByHostname } from './services/retailerService';
import { SrcPartnerEnum } from './utils/constants';

type Props = {
  children: ReactNode;
};

export type AppData = {
  categories: Category[];
  srcPartnerId: SrcPartnerEnum;
  features: FeatureFlags;
  setCategories: (categories: Category[]) => void;
  getCategoryNameById: (value: number) => string;
  setFeatures: (features: Partial<FeatureFlags>) => void;
  setSrcPartnerId: (srcPartnerId: number) => void;
};

export const AppContext = createContext<AppData>({
  categories: [],
  srcPartnerId: SrcPartnerEnum.None,
  features: {
    ablyChat: false,
    useRetailerLevelSMSOptIn: false
  },
  setCategories: () => null,
  getCategoryNameById: () => '',
  setFeatures: () => null,
  setSrcPartnerId: () => null,
});

export default function AppProvider({ children }: Props) {
  const [categories, setCategories] = useState<Category[]>([]);
  const [srcPartnerId, setSrcPartnerId] = useState<number>(0);
  const [features, setFeatures] = useState({});

  useEffect(() => {
    /** This is to make sure, that window fetch is done in the client to prevent build errors */
    setSrcPartnerId(getRetailerMappingByHostname(window.location.hostname).srcPartnerId);
  }, []);
  /**
   * Returns category name by categoryId
   * @param categoryId
   */
  const getCategoryNameById = (categoryId: number): string =>
    categories.find((c) => c.categoryId === categoryId)?.categoryName || '';

  return (
    <AppContext.Provider
      value={{
        categories,
        setCategories,
        srcPartnerId: srcPartnerId,
        getCategoryNameById,
        features,
        setFeatures,
        setSrcPartnerId,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
